.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.button{
  background-color: #00B4C6;
  color: white;
  padding: 0.9em 1.7em;
  margin: 0 0 0 25px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  text-decoration: none;
}
.button:hover{
  opacity:0.8;
}

.link_style_button{
  cursor: pointer;
  border: none;
  background: none;
  color: #0033cc;
  text-decoration: underline;
}

.file_upload_button_label{
  background-color: #00B4C6;
  color: white;
  padding: 0.9em 1.7em;
  margin: 0 0 0 25px;
	border-radius: 4px;
  border: 0px none;
  cursor: hand;
  cursor:pointer;
  width: 35px;
  height: 20px;
}
.file_upload_button_label:hover{
  opacity:0.8;
}

.file_upload_button{
  display: none;
}

.textbox{
  width: 30dp;
  margin: 0 0 0 25px;
  border: 1px solid #1b2538;
	border-radius: 4px;
  box-sizing: border-box;
  padding: 0.8em;
}

ul li {
  list-style: none;
}
ul{
  width: 800px;
  margin: 0 auto;
}
li{
  margin-top: 10px;
}
.li_left{
  width: 150px;
}
.test{
  background-color: yellow;
}
label {
  margin-right: 10px;
  width:100px;
  float: left;
}

.header{
  height: 100px;
  background-color: #FAFAFA;

  text-align: center;
}
.header_link:link, .header_link:visited, .header_link:hover, .header_link:active{
  color: #00B4C6;
  text-decoration: none;
}
.overall{
  margin-left: auto;
  margin-right: auto;
  width:1200px;
}
:root {
  --amplify-primary-color: #00B4C6;
  --amplify-primary-tint: #00B4C6;
  --amplify-primary-shade: #00B4C6;
}
